import {instance, onErrorFunction} from "../../config/axios-cnf";


export const apiGetSubject = (id, values, callback, error) => {
    if (id) {
        instance.get("/api/system/subject/" + id)
            .then(response => callback(response.data))
            .catch(err => error(onErrorFunction(err)))
    } else {
        instance.get("/api/system/subject", {params: values})
            .then(response => callback(response.data))
            .catch(err => error(onErrorFunction(err)))
    }
}

export const apiCreateSubject = (values, callback, error) => {
    instance.put("/api/system/subject", values)
        .then(response => callback(response.data))
        .catch(err => error(onErrorFunction(err)))
}

export const apiUpdateSubject = (id, values, callback, error) => {
    instance.post("/api/system/subject/" + id, values)
        .then(response => callback(response.data))
        .catch(err => error(onErrorFunction(err)))
}


export const apiSetBankToSubject = (subjectId, values, callback, error) => {
    instance.post("/api/system/subject/" + subjectId + "/bank", values)
        .then(response => callback(response.data))
        .catch(err => error(onErrorFunction(err)))
}

export const apiUpdateSubjectCommision = (id, values, callback, error) => {
    instance.post(`/api/system/subject/${id}/commision`, {commision: values})
        .then(response => callback(response.data))
        .catch(err => error(onErrorFunction(err)))
}

export const apiUpdateSubjectMarketData = (id, values, callback, error) => {
    instance.post(`/api/system/subject/${id}/market`, {market: values})
        .then(response => callback(response.data))
        .catch(err => error(onErrorFunction(err)))
}

export const apiCreateUpdateSubjectPeople = (subjectId, peopleId, values, callback, error) => {
    if (peopleId) {
        instance.put(`/api/system/subject/${subjectId}/people/${peopleId}`, values)
            .then(response => callback(response.data))
            .catch(err => error(onErrorFunction(err)))
    } else {
        instance.post(`/api/system/subject/${subjectId}/people`, values)
            .then(response => callback(response.data))
            .catch(err => error(onErrorFunction(err)))
    }


}
