import React, {useState} from "react"
import {Filter} from "./Filter";
import {apiGetEvents} from "./Actions";
import TableData from "./TableData";

const Contact = () => {

    let [loading, setLoading] = useState(false)
    let [data, setData] = useState(null)

    const getEvent = (values) => {
        setData(null)
        setLoading(true)
        apiGetEvents(values.subjectId, data =>{
            setData(data)
            setLoading(false)
        }, error =>{
            setLoading(false)
            setData(null)
        })
    }

    return(
        <>
            <Filter getEvent={getEvent} loading={loading} setData={setData} />
            <TableData data={data}/>
        </>
    )

}

export default Contact
