import {Form} from "react-final-form";
import SubjectTypeField from "../../FinalForm/api/SubjectTypeField";
import {Button} from "../../components/My/Button";
import React from "react";
import Widget from "../../components/widget";
import SelectField from "../../FinalForm/SelectField";
import SubjectField from "../../FinalForm/api/SubjectField";


export const Filter = ({getEvent, loading, setData}) => {
    return (
        <Widget>
            <div className={"flex flex-col"}>
                <Form onSubmit={getEvent}
                      render={({handleSubmit}) => (
                          <form className="form flex flex-wrap w-full">
                              <div className="w-1/4">
                                  <SubjectTypeField name={"subjectType"} label={"Vyberte typ subjektů"} isMulti={true}
                                                onEnter={() => handleSubmit()} onChange={() => setData()}/>
                              </div>
                              <div className="w-1/4">
                                  <SubjectField name={"subjectId"} label={"Vyberte subjekt"} isMulti={true}
                                                onEnter={() => handleSubmit()} onChange={() => setData()}/>
                              </div>
                              <div className="w-full">
                                  <Button onClick={handleSubmit} text={"Potvrdit"} loading={loading}/>
                              </div>
                          </form>
                      )}
                />
            </div>
        </Widget>
    )
}
